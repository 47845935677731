import Ajv from "ajv";
import AGENT_BASIC_INFO from '../../node_modules/agent-basic-info/package.json';
import AGENTLOGIN from '../../node_modules/agentlogin/package.json';
import MENU_ACTIONS from '../../node_modules/menu-actions/package.json';
import CUSTOMER_SEARCH_WIDGET from '../../node_modules/customer-search-widget/package.json';
import CUSTOMER_INFORMATION_BANNER from '../../node_modules/customer-information-banner/package.json';
import PRODUCTS_AND_SERVICES from '../../node_modules/products-and-services/package.json';
import NAVIGATION_HEADER from '../../node_modules/navigation-header/package.json';
import ERROR_MODAL from '../../node_modules/error-modal/package.json';
import GENERIC_ACTIONS from '../../node_modules/generic-actions/package.json';
import TOPUP from '../../node_modules/topup/package.json';
import PLAN_TYPE_MODAL from '../../node_modules/plan-type-modal/package.json';
import BUNDLE_DETAILS from '../../node_modules/bundle-details/package.json';
import SERVICE_DETAILS_HEADER from '../../node_modules/service-details-header/package.json';
import SERVICE_TAGS from '../../node_modules/service-tags/package.json';
import PLANS_CARD from '../../node_modules/plans-card/package.json';
import CONSUMPTION_CARD from '../../node_modules/consumption-card/package.json';
import PEGA_OFFERS from '../../node_modules/pega-offers/package.json';
import BARRING_MANAGEMENT from '../../node_modules/barring-management/package.json';
import CUSTOMER_DASHBOARD_ADDONS from '../../node_modules/customer-dashboard-addons/package.json';
import PROCESS_FLOW_STEPPER from '../../node_modules/process-flow-stepper/package.json';
import TOPUP_SELECTOR from '../../node_modules/topup-selector/package.json';
import SIM_SELECTOR from '../../node_modules/sim-selector/package.json';
import PRODUCT_SELECTOR from '../../node_modules/product-selector/package.json';
import PEGA_DETAILS from '../../node_modules/pega-details/package.json';
import ADDON_DETAILS_POPUP from '../../node_modules/addon-details-popup/package.json';

const ajv = new Ajv({
  useDefaults: true,
  allErrors: true,
  jsonPointers: true
});

class ValidationService {
  validators = {}

  init = () => {
    if(AGENT_BASIC_INFO["app-framework"] && AGENT_BASIC_INFO["app-framework"]["actions"]) {
      this.validators = {
        ...this.validators,
        ...Object.fromEntries(Object.entries(AGENT_BASIC_INFO["app-framework"]["actions"])
        .map(([name, value]) => [`@agent-basic-info/${name}`, ajv.compile(value)]))
      };
    }
    if(AGENTLOGIN["app-framework"] && AGENTLOGIN["app-framework"]["actions"]) {
      this.validators = {
        ...this.validators,
        ...Object.fromEntries(Object.entries(AGENTLOGIN["app-framework"]["actions"])
        .map(([name, value]) => [`@agentlogin/${name}`, ajv.compile(value)]))
      };
    }
    if(MENU_ACTIONS["app-framework"] && MENU_ACTIONS["app-framework"]["actions"]) {
      this.validators = {
        ...this.validators,
        ...Object.fromEntries(Object.entries(MENU_ACTIONS["app-framework"]["actions"])
        .map(([name, value]) => [`@menu-actions/${name}`, ajv.compile(value)]))
      };
    }
    if(CUSTOMER_SEARCH_WIDGET["app-framework"] && CUSTOMER_SEARCH_WIDGET["app-framework"]["actions"]) {
      this.validators = {
        ...this.validators,
        ...Object.fromEntries(Object.entries(CUSTOMER_SEARCH_WIDGET["app-framework"]["actions"])
        .map(([name, value]) => [`@customer-search-widget/${name}`, ajv.compile(value)]))
      };
    }
    if(CUSTOMER_INFORMATION_BANNER["app-framework"] && CUSTOMER_INFORMATION_BANNER["app-framework"]["actions"]) {
      this.validators = {
        ...this.validators,
        ...Object.fromEntries(Object.entries(CUSTOMER_INFORMATION_BANNER["app-framework"]["actions"])
        .map(([name, value]) => [`@customer-information-banner/${name}`, ajv.compile(value)]))
      };
    }
    if(PRODUCTS_AND_SERVICES["app-framework"] && PRODUCTS_AND_SERVICES["app-framework"]["actions"]) {
      this.validators = {
        ...this.validators,
        ...Object.fromEntries(Object.entries(PRODUCTS_AND_SERVICES["app-framework"]["actions"])
        .map(([name, value]) => [`@products-and-services/${name}`, ajv.compile(value)]))
      };
    }
    if(NAVIGATION_HEADER["app-framework"] && NAVIGATION_HEADER["app-framework"]["actions"]) {
      this.validators = {
        ...this.validators,
        ...Object.fromEntries(Object.entries(NAVIGATION_HEADER["app-framework"]["actions"])
        .map(([name, value]) => [`@navigation-header/${name}`, ajv.compile(value)]))
      };
    }
    if(ERROR_MODAL["app-framework"] && ERROR_MODAL["app-framework"]["actions"]) {
      this.validators = {
        ...this.validators,
        ...Object.fromEntries(Object.entries(ERROR_MODAL["app-framework"]["actions"])
        .map(([name, value]) => [`@error-modal/${name}`, ajv.compile(value)]))
      };
    }
    if(GENERIC_ACTIONS["app-framework"] && GENERIC_ACTIONS["app-framework"]["actions"]) {
      this.validators = {
        ...this.validators,
        ...Object.fromEntries(Object.entries(GENERIC_ACTIONS["app-framework"]["actions"])
        .map(([name, value]) => [`@generic-actions/${name}`, ajv.compile(value)]))
      };
    }
    if(TOPUP["app-framework"] && TOPUP["app-framework"]["actions"]) {
      this.validators = {
        ...this.validators,
        ...Object.fromEntries(Object.entries(TOPUP["app-framework"]["actions"])
        .map(([name, value]) => [`@topup/${name}`, ajv.compile(value)]))
      };
    }
    if(PLAN_TYPE_MODAL["app-framework"] && PLAN_TYPE_MODAL["app-framework"]["actions"]) {
      this.validators = {
        ...this.validators,
        ...Object.fromEntries(Object.entries(PLAN_TYPE_MODAL["app-framework"]["actions"])
        .map(([name, value]) => [`@plan-type-modal/${name}`, ajv.compile(value)]))
      };
    }
    if(BUNDLE_DETAILS["app-framework"] && BUNDLE_DETAILS["app-framework"]["actions"]) {
      this.validators = {
        ...this.validators,
        ...Object.fromEntries(Object.entries(BUNDLE_DETAILS["app-framework"]["actions"])
        .map(([name, value]) => [`@bundle-details/${name}`, ajv.compile(value)]))
      };
    }
    if(SERVICE_DETAILS_HEADER["app-framework"] && SERVICE_DETAILS_HEADER["app-framework"]["actions"]) {
      this.validators = {
        ...this.validators,
        ...Object.fromEntries(Object.entries(SERVICE_DETAILS_HEADER["app-framework"]["actions"])
        .map(([name, value]) => [`@service-details-header/${name}`, ajv.compile(value)]))
      };
    }
    if(SERVICE_TAGS["app-framework"] && SERVICE_TAGS["app-framework"]["actions"]) {
      this.validators = {
        ...this.validators,
        ...Object.fromEntries(Object.entries(SERVICE_TAGS["app-framework"]["actions"])
        .map(([name, value]) => [`@service-tags/${name}`, ajv.compile(value)]))
      };
    }
    if(PLANS_CARD["app-framework"] && PLANS_CARD["app-framework"]["actions"]) {
      this.validators = {
        ...this.validators,
        ...Object.fromEntries(Object.entries(PLANS_CARD["app-framework"]["actions"])
        .map(([name, value]) => [`@plans-card/${name}`, ajv.compile(value)]))
      };
    }
    if(CONSUMPTION_CARD["app-framework"] && CONSUMPTION_CARD["app-framework"]["actions"]) {
      this.validators = {
        ...this.validators,
        ...Object.fromEntries(Object.entries(CONSUMPTION_CARD["app-framework"]["actions"])
        .map(([name, value]) => [`@consumption-card/${name}`, ajv.compile(value)]))
      };
    }
    if(PEGA_OFFERS["app-framework"] && PEGA_OFFERS["app-framework"]["actions"]) {
      this.validators = {
        ...this.validators,
        ...Object.fromEntries(Object.entries(PEGA_OFFERS["app-framework"]["actions"])
        .map(([name, value]) => [`@pega-offers/${name}`, ajv.compile(value)]))
      };
    }
    if(BARRING_MANAGEMENT["app-framework"] && BARRING_MANAGEMENT["app-framework"]["actions"]) {
      this.validators = {
        ...this.validators,
        ...Object.fromEntries(Object.entries(BARRING_MANAGEMENT["app-framework"]["actions"])
        .map(([name, value]) => [`@barring-management/${name}`, ajv.compile(value)]))
      };
    }
    if(CUSTOMER_DASHBOARD_ADDONS["app-framework"] && CUSTOMER_DASHBOARD_ADDONS["app-framework"]["actions"]) {
      this.validators = {
        ...this.validators,
        ...Object.fromEntries(Object.entries(CUSTOMER_DASHBOARD_ADDONS["app-framework"]["actions"])
        .map(([name, value]) => [`@customer-dashboard-addons/${name}`, ajv.compile(value)]))
      };
    }
    if(PROCESS_FLOW_STEPPER["app-framework"] && PROCESS_FLOW_STEPPER["app-framework"]["actions"]) {
      this.validators = {
        ...this.validators,
        ...Object.fromEntries(Object.entries(PROCESS_FLOW_STEPPER["app-framework"]["actions"])
        .map(([name, value]) => [`@process-flow-stepper/${name}`, ajv.compile(value)]))
      };
    }
    if(TOPUP_SELECTOR["app-framework"] && TOPUP_SELECTOR["app-framework"]["actions"]) {
      this.validators = {
        ...this.validators,
        ...Object.fromEntries(Object.entries(TOPUP_SELECTOR["app-framework"]["actions"])
        .map(([name, value]) => [`@topup-selector/${name}`, ajv.compile(value)]))
      };
    }
    if(SIM_SELECTOR["app-framework"] && SIM_SELECTOR["app-framework"]["actions"]) {
      this.validators = {
        ...this.validators,
        ...Object.fromEntries(Object.entries(SIM_SELECTOR["app-framework"]["actions"])
        .map(([name, value]) => [`@sim-selector/${name}`, ajv.compile(value)]))
      };
    }
    if(PRODUCT_SELECTOR["app-framework"] && PRODUCT_SELECTOR["app-framework"]["actions"]) {
      this.validators = {
        ...this.validators,
        ...Object.fromEntries(Object.entries(PRODUCT_SELECTOR["app-framework"]["actions"])
        .map(([name, value]) => [`@product-selector/${name}`, ajv.compile(value)]))
      };
    }
    if(PEGA_DETAILS["app-framework"] && PEGA_DETAILS["app-framework"]["actions"]) {
      this.validators = {
        ...this.validators,
        ...Object.fromEntries(Object.entries(PEGA_DETAILS["app-framework"]["actions"])
        .map(([name, value]) => [`@pega-details/${name}`, ajv.compile(value)]))
      };
    }
    if(ADDON_DETAILS_POPUP["app-framework"] && ADDON_DETAILS_POPUP["app-framework"]["actions"]) {
      this.validators = {
        ...this.validators,
        ...Object.fromEntries(Object.entries(ADDON_DETAILS_POPUP["app-framework"]["actions"])
        .map(([name, value]) => [`@addon-details-popup/${name}`, ajv.compile(value)]))
      };
    }
  };

  validateable = (action) => this.validators[action.type]

  validate = async (action) => this.validators[action.type](action.payload)
}

const inst = new ValidationService();
inst.init();
export default inst;
