//Created by buildtools at 2023-07-31T22:26:02.651Z
import { Switch, Route, Link, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import React, { Fragment } from 'react';
import { useDispatch, useSelector, connect } from "react-redux";
import { useEffect } from "react";
import { cloneDeep } from "lodash";
import jp from "jsonpath";
import { v4 as uuidv4 } from 'uuid';
import atomics from "vf-comps/build/components";
import dot from 'dot-object';
import merge from 'deepmerge';
import AGENT_BASIC_INFO from 'agent-basic-info';
import AGENT_BASIC_INFO_pckg from '../../node_modules/agent-basic-info/package.json';
import AGENTLOGIN from 'agentlogin';
import AGENTLOGIN_pckg from '../../node_modules/agentlogin/package.json';
import MENU_ACTIONS from 'menu-actions';
import MENU_ACTIONS_pckg from '../../node_modules/menu-actions/package.json';
import CUSTOMER_SEARCH_WIDGET from 'customer-search-widget';
import CUSTOMER_SEARCH_WIDGET_pckg from '../../node_modules/customer-search-widget/package.json';
import CUSTOMER_INFORMATION_BANNER from 'customer-information-banner';
import CUSTOMER_INFORMATION_BANNER_pckg from '../../node_modules/customer-information-banner/package.json';
import PRODUCTS_AND_SERVICES from 'products-and-services';
import PRODUCTS_AND_SERVICES_pckg from '../../node_modules/products-and-services/package.json';
import NAVIGATION_HEADER from 'navigation-header';
import NAVIGATION_HEADER_pckg from '../../node_modules/navigation-header/package.json';
import ERROR_MODAL from 'error-modal';
import ERROR_MODAL_pckg from '../../node_modules/error-modal/package.json';
import GENERIC_ACTIONS from 'generic-actions';
import GENERIC_ACTIONS_pckg from '../../node_modules/generic-actions/package.json';
import TOPUP from 'topup';
import TOPUP_pckg from '../../node_modules/topup/package.json';
import PLAN_TYPE_MODAL from 'plan-type-modal';
import PLAN_TYPE_MODAL_pckg from '../../node_modules/plan-type-modal/package.json';
import BUNDLE_DETAILS from 'bundle-details';
import BUNDLE_DETAILS_pckg from '../../node_modules/bundle-details/package.json';
import SERVICE_DETAILS_HEADER from 'service-details-header';
import SERVICE_DETAILS_HEADER_pckg from '../../node_modules/service-details-header/package.json';
import SERVICE_TAGS from 'service-tags';
import SERVICE_TAGS_pckg from '../../node_modules/service-tags/package.json';
import PLANS_CARD from 'plans-card';
import PLANS_CARD_pckg from '../../node_modules/plans-card/package.json';
import CONSUMPTION_CARD from 'consumption-card';
import CONSUMPTION_CARD_pckg from '../../node_modules/consumption-card/package.json';
import PEGA_OFFERS from 'pega-offers';
import PEGA_OFFERS_pckg from '../../node_modules/pega-offers/package.json';
import BARRING_MANAGEMENT from 'barring-management';
import BARRING_MANAGEMENT_pckg from '../../node_modules/barring-management/package.json';
import CUSTOMER_DASHBOARD_ADDONS from 'customer-dashboard-addons';
import CUSTOMER_DASHBOARD_ADDONS_pckg from '../../node_modules/customer-dashboard-addons/package.json';
import PROCESS_FLOW_STEPPER from 'process-flow-stepper';
import PROCESS_FLOW_STEPPER_pckg from '../../node_modules/process-flow-stepper/package.json';
import TOPUP_SELECTOR from 'topup-selector';
import TOPUP_SELECTOR_pckg from '../../node_modules/topup-selector/package.json';
import SIM_SELECTOR from 'sim-selector';
import SIM_SELECTOR_pckg from '../../node_modules/sim-selector/package.json';
import PRODUCT_SELECTOR from 'product-selector';
import PRODUCT_SELECTOR_pckg from '../../node_modules/product-selector/package.json';
import PEGA_DETAILS from 'pega-details';
import PEGA_DETAILS_pckg from '../../node_modules/pega-details/package.json';
import ADDON_DETAILS_POPUP from 'addon-details-popup';
import ADDON_DETAILS_POPUP_pckg from '../../node_modules/addon-details-popup/package.json';
import createDynamicJourneyRenderer from "@vf-djr/renderer";
import mapStateToPropsPlugin from "@vf-djr-plugins/map-state-to-props/lib";
import componentRenderingPlugin from "@vf-djr-plugins/component-rendering";
import conditionalRenderingPlugin from "@vf-djr-plugins/conditional-rendering";
import Box from 'components/Box';
import Acronym from 'components/Acronym';
import Sidebar from 'components/Sidebar';
import PrivateRoute from '../routes/PrivateRoute';
import OauthCode from 'components/OauthCode';
import NotFoundPage from '../components/NotFound';
import Signin from '../components/Signin';
import Logout from '../components/Logout';
import { store } from 'store/configureStore';
import TranslationService from 'services/TranslationService';
import { setProps } from "actions/propActions";
import * as functions from 'functions';

import MaterialGrid from "../components/grid/Grid";
import theme from 'vf-comps/build/AppTheme';
import { history } from '../sharedHistory';

const selectors = {
  not: value => !value,
  toString: value => value?.toString(),
};



const apis = {
};

store.dispatch({type:'SET_IS_AUTH_APP', payload: true });

const createBaseUrl = (pageId) => {
  const pages = [
    { id: "64b5317959c19d71962de87d", isHome: false, title: "customersearch" },
    { id: "64b5317959c19d71962de87e", isHome: false, title: "customerdashboard" },
    { id: "64b5317959c19d71962de883", isHome: false, title: "activebundledetails" },
    { id: "64b5317959c19d71962de889", isHome: false, title: "generic" },
    { id: "64b5317959c19d71962de893", isHome: false, title: "sellablebundledetails" },
    { id: "64b5317959c19d71962de898", isHome: false, title: "prepaiddashboard" },
    { id: "64b5317959c19d71962de8d2", isHome: true, title: "agentdashboard" },
    { id: "64b5321359c19d71962ded00", isHome: false, title: "stepper" },
    { id: "64b53d2c59c19d71962e3265", isHome: false, title: "campaigndetails" },
  ];
  const hasAuth = true;
  let baseUrl = '/';

  const currentPage = pages.find((p) => p.id === pageId);

  if (currentPage?.isHome)
    baseUrl += hasAuth ? 'dashboard' : '';
  else if (currentPage && !currentPage.isHome)
    baseUrl += `page/${currentPage.title}`;

  baseUrl = baseUrl.endsWith('/') ? baseUrl.slice(0, -1) : baseUrl;
  
  return baseUrl;
};

const propsAndApis = (props, pckg) => ({
  __baseUrl: createBaseUrl(props.__pageId),
  ...props,
  ...Object.fromEntries(Object.entries(apis).filter(([k, v]) => (pckg["app-framework"] && pckg["app-framework"]["apis"] && pckg["app-framework"]["apis"].includes(k)) ? v : null))
});

const AGENT_BASIC_INFO_wrapper = props => {
  const dispatch = useDispatch();
  const language = useSelector(state => state.language && state.language.language);
  const reduxPerInstance = AGENT_BASIC_INFO_pckg['app-framework'] && AGENT_BASIC_INFO_pckg['app-framework'].reduxPerInstance;
  const config = useSelector(state => (state.config.data && state.config.data.widgets["agent-basic-info"] && state.config.data.widgets["agent-basic-info"][props.__instanceId]) ?? undefined);
  const perInstanceState = useSelector(state => (state['agent-basic-info'] ?? {})[props.__instanceId]) ?? undefined;
  const singleState = useSelector(state => state['agent-basic-info']) ?? undefined;
  const state = reduxPerInstance ? perInstanceState : singleState;
  return (
    <AGENT_BASIC_INFO
      {...propsAndApis(props, AGENT_BASIC_INFO_pckg)}
      theme={theme}
      dispatch={dispatch}
      config={config}
      state={state}
      language={language}
      i18n={TranslationService.moduleT('agent-basic-info')}>
    </AGENT_BASIC_INFO>
  );
};
const AGENTLOGIN_wrapper = props => {
  const dispatch = useDispatch();
  const language = useSelector(state => state.language && state.language.language);
  const reduxPerInstance = AGENTLOGIN_pckg['app-framework'] && AGENTLOGIN_pckg['app-framework'].reduxPerInstance;
  const config = useSelector(state => (state.config.data && state.config.data.widgets["agentlogin"] && state.config.data.widgets["agentlogin"][props.__instanceId]) ?? undefined);
  const perInstanceState = useSelector(state => (state['agentlogin'] ?? {})[props.__instanceId]) ?? undefined;
  const singleState = useSelector(state => state['agentlogin']) ?? undefined;
  const state = reduxPerInstance ? perInstanceState : singleState;
  return (
    <AGENTLOGIN
      {...propsAndApis(props, AGENTLOGIN_pckg)}
      theme={theme}
      dispatch={dispatch}
      config={config}
      state={state}
      language={language}
      i18n={TranslationService.moduleT('agentlogin')}>
    </AGENTLOGIN>
  );
};
const MENU_ACTIONS_wrapper = props => {
  const dispatch = useDispatch();
  const language = useSelector(state => state.language && state.language.language);
  const reduxPerInstance = MENU_ACTIONS_pckg['app-framework'] && MENU_ACTIONS_pckg['app-framework'].reduxPerInstance;
  const config = useSelector(state => (state.config.data && state.config.data.widgets["menu-actions"] && state.config.data.widgets["menu-actions"][props.__instanceId]) ?? undefined);
  const perInstanceState = useSelector(state => (state['menu-actions'] ?? {})[props.__instanceId]) ?? undefined;
  const singleState = useSelector(state => state['menu-actions']) ?? undefined;
  const state = reduxPerInstance ? perInstanceState : singleState;
  return (
    <MENU_ACTIONS
      {...propsAndApis(props, MENU_ACTIONS_pckg)}
      theme={theme}
      dispatch={dispatch}
      config={config}
      state={state}
      language={language}
      i18n={TranslationService.moduleT('menu-actions')}>
    </MENU_ACTIONS>
  );
};
const CUSTOMER_SEARCH_WIDGET_wrapper = props => {
  const dispatch = useDispatch();
  const language = useSelector(state => state.language && state.language.language);
  const reduxPerInstance = CUSTOMER_SEARCH_WIDGET_pckg['app-framework'] && CUSTOMER_SEARCH_WIDGET_pckg['app-framework'].reduxPerInstance;
  const config = useSelector(state => (state.config.data && state.config.data.widgets["customer-search-widget"] && state.config.data.widgets["customer-search-widget"][props.__instanceId]) ?? undefined);
  const perInstanceState = useSelector(state => (state['customer-search-widget'] ?? {})[props.__instanceId]) ?? undefined;
  const singleState = useSelector(state => state['customer-search-widget']) ?? undefined;
  const state = reduxPerInstance ? perInstanceState : singleState;
  return (
    <CUSTOMER_SEARCH_WIDGET
      {...propsAndApis(props, CUSTOMER_SEARCH_WIDGET_pckg)}
      theme={theme}
      dispatch={dispatch}
      config={config}
      state={state}
      language={language}
      i18n={TranslationService.moduleT('customer-search-widget')}>
    </CUSTOMER_SEARCH_WIDGET>
  );
};
const CUSTOMER_INFORMATION_BANNER_wrapper = props => {
  const dispatch = useDispatch();
  const language = useSelector(state => state.language && state.language.language);
  const reduxPerInstance = CUSTOMER_INFORMATION_BANNER_pckg['app-framework'] && CUSTOMER_INFORMATION_BANNER_pckg['app-framework'].reduxPerInstance;
  const config = useSelector(state => (state.config.data && state.config.data.widgets["customer-information-banner"] && state.config.data.widgets["customer-information-banner"][props.__instanceId]) ?? undefined);
  const perInstanceState = useSelector(state => (state['customer-information-banner'] ?? {})[props.__instanceId]) ?? undefined;
  const singleState = useSelector(state => state['customer-information-banner']) ?? undefined;
  const state = reduxPerInstance ? perInstanceState : singleState;
  return (
    <CUSTOMER_INFORMATION_BANNER
      {...propsAndApis(props, CUSTOMER_INFORMATION_BANNER_pckg)}
      theme={theme}
      dispatch={dispatch}
      config={config}
      state={state}
      language={language}
      i18n={TranslationService.moduleT('customer-information-banner')}>
    </CUSTOMER_INFORMATION_BANNER>
  );
};
const PRODUCTS_AND_SERVICES_wrapper = props => {
  const dispatch = useDispatch();
  const language = useSelector(state => state.language && state.language.language);
  const reduxPerInstance = PRODUCTS_AND_SERVICES_pckg['app-framework'] && PRODUCTS_AND_SERVICES_pckg['app-framework'].reduxPerInstance;
  const config = useSelector(state => (state.config.data && state.config.data.widgets["products-and-services"] && state.config.data.widgets["products-and-services"][props.__instanceId]) ?? undefined);
  const perInstanceState = useSelector(state => (state['products-and-services'] ?? {})[props.__instanceId]) ?? undefined;
  const singleState = useSelector(state => state['products-and-services']) ?? undefined;
  const state = reduxPerInstance ? perInstanceState : singleState;
  return (
    <PRODUCTS_AND_SERVICES
      {...propsAndApis(props, PRODUCTS_AND_SERVICES_pckg)}
      theme={theme}
      dispatch={dispatch}
      config={config}
      state={state}
      language={language}
      i18n={TranslationService.moduleT('products-and-services')}>
    </PRODUCTS_AND_SERVICES>
  );
};
const NAVIGATION_HEADER_wrapper = props => {
  const dispatch = useDispatch();
  const language = useSelector(state => state.language && state.language.language);
  const reduxPerInstance = NAVIGATION_HEADER_pckg['app-framework'] && NAVIGATION_HEADER_pckg['app-framework'].reduxPerInstance;
  const config = useSelector(state => (state.config.data && state.config.data.widgets["navigation-header"] && state.config.data.widgets["navigation-header"][props.__instanceId]) ?? undefined);
  const perInstanceState = useSelector(state => (state['navigation-header'] ?? {})[props.__instanceId]) ?? undefined;
  const singleState = useSelector(state => state['navigation-header']) ?? undefined;
  const state = reduxPerInstance ? perInstanceState : singleState;
  return (
    <NAVIGATION_HEADER
      {...propsAndApis(props, NAVIGATION_HEADER_pckg)}
      theme={theme}
      dispatch={dispatch}
      config={config}
      state={state}
      language={language}
      i18n={TranslationService.moduleT('navigation-header')}>
    </NAVIGATION_HEADER>
  );
};
const ERROR_MODAL_wrapper = props => {
  const dispatch = useDispatch();
  const language = useSelector(state => state.language && state.language.language);
  const reduxPerInstance = ERROR_MODAL_pckg['app-framework'] && ERROR_MODAL_pckg['app-framework'].reduxPerInstance;
  const config = useSelector(state => (state.config.data && state.config.data.widgets["error-modal"] && state.config.data.widgets["error-modal"][props.__instanceId]) ?? undefined);
  const perInstanceState = useSelector(state => (state['error-modal'] ?? {})[props.__instanceId]) ?? undefined;
  const singleState = useSelector(state => state['error-modal']) ?? undefined;
  const state = reduxPerInstance ? perInstanceState : singleState;
  return (
    <ERROR_MODAL
      {...propsAndApis(props, ERROR_MODAL_pckg)}
      theme={theme}
      dispatch={dispatch}
      config={config}
      state={state}
      language={language}
      i18n={TranslationService.moduleT('error-modal')}>
    </ERROR_MODAL>
  );
};
const GENERIC_ACTIONS_wrapper = props => {
  const dispatch = useDispatch();
  const language = useSelector(state => state.language && state.language.language);
  const reduxPerInstance = GENERIC_ACTIONS_pckg['app-framework'] && GENERIC_ACTIONS_pckg['app-framework'].reduxPerInstance;
  const config = useSelector(state => (state.config.data && state.config.data.widgets["generic-actions"] && state.config.data.widgets["generic-actions"][props.__instanceId]) ?? undefined);
  const perInstanceState = useSelector(state => (state['generic-actions'] ?? {})[props.__instanceId]) ?? undefined;
  const singleState = useSelector(state => state['generic-actions']) ?? undefined;
  const state = reduxPerInstance ? perInstanceState : singleState;
  return (
    <GENERIC_ACTIONS
      {...propsAndApis(props, GENERIC_ACTIONS_pckg)}
      theme={theme}
      dispatch={dispatch}
      config={config}
      state={state}
      language={language}
      i18n={TranslationService.moduleT('generic-actions')}>
    </GENERIC_ACTIONS>
  );
};
const TOPUP_wrapper = props => {
  const dispatch = useDispatch();
  const language = useSelector(state => state.language && state.language.language);
  const reduxPerInstance = TOPUP_pckg['app-framework'] && TOPUP_pckg['app-framework'].reduxPerInstance;
  const config = useSelector(state => (state.config.data && state.config.data.widgets["topup"] && state.config.data.widgets["topup"][props.__instanceId]) ?? undefined);
  const perInstanceState = useSelector(state => (state['topup'] ?? {})[props.__instanceId]) ?? undefined;
  const singleState = useSelector(state => state['topup']) ?? undefined;
  const state = reduxPerInstance ? perInstanceState : singleState;
  return (
    <TOPUP
      {...propsAndApis(props, TOPUP_pckg)}
      theme={theme}
      dispatch={dispatch}
      config={config}
      state={state}
      language={language}
      i18n={TranslationService.moduleT('topup')}>
    </TOPUP>
  );
};
const PLAN_TYPE_MODAL_wrapper = props => {
  const dispatch = useDispatch();
  const language = useSelector(state => state.language && state.language.language);
  const reduxPerInstance = PLAN_TYPE_MODAL_pckg['app-framework'] && PLAN_TYPE_MODAL_pckg['app-framework'].reduxPerInstance;
  const config = useSelector(state => (state.config.data && state.config.data.widgets["plan-type-modal"] && state.config.data.widgets["plan-type-modal"][props.__instanceId]) ?? undefined);
  const perInstanceState = useSelector(state => (state['plan-type-modal'] ?? {})[props.__instanceId]) ?? undefined;
  const singleState = useSelector(state => state['plan-type-modal']) ?? undefined;
  const state = reduxPerInstance ? perInstanceState : singleState;
  return (
    <PLAN_TYPE_MODAL
      {...propsAndApis(props, PLAN_TYPE_MODAL_pckg)}
      theme={theme}
      dispatch={dispatch}
      config={config}
      state={state}
      language={language}
      i18n={TranslationService.moduleT('plan-type-modal')}>
    </PLAN_TYPE_MODAL>
  );
};
const BUNDLE_DETAILS_wrapper = props => {
  const dispatch = useDispatch();
  const language = useSelector(state => state.language && state.language.language);
  const reduxPerInstance = BUNDLE_DETAILS_pckg['app-framework'] && BUNDLE_DETAILS_pckg['app-framework'].reduxPerInstance;
  const config = useSelector(state => (state.config.data && state.config.data.widgets["bundle-details"] && state.config.data.widgets["bundle-details"][props.__instanceId]) ?? undefined);
  const perInstanceState = useSelector(state => (state['bundle-details'] ?? {})[props.__instanceId]) ?? undefined;
  const singleState = useSelector(state => state['bundle-details']) ?? undefined;
  const state = reduxPerInstance ? perInstanceState : singleState;
  return (
    <BUNDLE_DETAILS
      {...propsAndApis(props, BUNDLE_DETAILS_pckg)}
      theme={theme}
      dispatch={dispatch}
      config={config}
      state={state}
      language={language}
      i18n={TranslationService.moduleT('bundle-details')}>
    </BUNDLE_DETAILS>
  );
};
const SERVICE_DETAILS_HEADER_wrapper = props => {
  const dispatch = useDispatch();
  const language = useSelector(state => state.language && state.language.language);
  const reduxPerInstance = SERVICE_DETAILS_HEADER_pckg['app-framework'] && SERVICE_DETAILS_HEADER_pckg['app-framework'].reduxPerInstance;
  const config = useSelector(state => (state.config.data && state.config.data.widgets["service-details-header"] && state.config.data.widgets["service-details-header"][props.__instanceId]) ?? undefined);
  const perInstanceState = useSelector(state => (state['service-details-header'] ?? {})[props.__instanceId]) ?? undefined;
  const singleState = useSelector(state => state['service-details-header']) ?? undefined;
  const state = reduxPerInstance ? perInstanceState : singleState;
  return (
    <SERVICE_DETAILS_HEADER
      {...propsAndApis(props, SERVICE_DETAILS_HEADER_pckg)}
      theme={theme}
      dispatch={dispatch}
      config={config}
      state={state}
      language={language}
      i18n={TranslationService.moduleT('service-details-header')}>
    </SERVICE_DETAILS_HEADER>
  );
};
const SERVICE_TAGS_wrapper = props => {
  const dispatch = useDispatch();
  const language = useSelector(state => state.language && state.language.language);
  const reduxPerInstance = SERVICE_TAGS_pckg['app-framework'] && SERVICE_TAGS_pckg['app-framework'].reduxPerInstance;
  const config = useSelector(state => (state.config.data && state.config.data.widgets["service-tags"] && state.config.data.widgets["service-tags"][props.__instanceId]) ?? undefined);
  const perInstanceState = useSelector(state => (state['service-tags'] ?? {})[props.__instanceId]) ?? undefined;
  const singleState = useSelector(state => state['service-tags']) ?? undefined;
  const state = reduxPerInstance ? perInstanceState : singleState;
  return (
    <SERVICE_TAGS
      {...propsAndApis(props, SERVICE_TAGS_pckg)}
      theme={theme}
      dispatch={dispatch}
      config={config}
      state={state}
      language={language}
      i18n={TranslationService.moduleT('service-tags')}>
    </SERVICE_TAGS>
  );
};
const PLANS_CARD_wrapper = props => {
  const dispatch = useDispatch();
  const language = useSelector(state => state.language && state.language.language);
  const reduxPerInstance = PLANS_CARD_pckg['app-framework'] && PLANS_CARD_pckg['app-framework'].reduxPerInstance;
  const config = useSelector(state => (state.config.data && state.config.data.widgets["plans-card"] && state.config.data.widgets["plans-card"][props.__instanceId]) ?? undefined);
  const perInstanceState = useSelector(state => (state['plans-card'] ?? {})[props.__instanceId]) ?? undefined;
  const singleState = useSelector(state => state['plans-card']) ?? undefined;
  const state = reduxPerInstance ? perInstanceState : singleState;
  return (
    <PLANS_CARD
      {...propsAndApis(props, PLANS_CARD_pckg)}
      theme={theme}
      dispatch={dispatch}
      config={config}
      state={state}
      language={language}
      i18n={TranslationService.moduleT('plans-card')}>
    </PLANS_CARD>
  );
};
const CONSUMPTION_CARD_wrapper = props => {
  const dispatch = useDispatch();
  const language = useSelector(state => state.language && state.language.language);
  const reduxPerInstance = CONSUMPTION_CARD_pckg['app-framework'] && CONSUMPTION_CARD_pckg['app-framework'].reduxPerInstance;
  const config = useSelector(state => (state.config.data && state.config.data.widgets["consumption-card"] && state.config.data.widgets["consumption-card"][props.__instanceId]) ?? undefined);
  const perInstanceState = useSelector(state => (state['consumption-card'] ?? {})[props.__instanceId]) ?? undefined;
  const singleState = useSelector(state => state['consumption-card']) ?? undefined;
  const state = reduxPerInstance ? perInstanceState : singleState;
  return (
    <CONSUMPTION_CARD
      {...propsAndApis(props, CONSUMPTION_CARD_pckg)}
      theme={theme}
      dispatch={dispatch}
      config={config}
      state={state}
      language={language}
      i18n={TranslationService.moduleT('consumption-card')}>
    </CONSUMPTION_CARD>
  );
};
const PEGA_OFFERS_wrapper = props => {
  const dispatch = useDispatch();
  const language = useSelector(state => state.language && state.language.language);
  const reduxPerInstance = PEGA_OFFERS_pckg['app-framework'] && PEGA_OFFERS_pckg['app-framework'].reduxPerInstance;
  const config = useSelector(state => (state.config.data && state.config.data.widgets["pega-offers"] && state.config.data.widgets["pega-offers"][props.__instanceId]) ?? undefined);
  const perInstanceState = useSelector(state => (state['pega-offers'] ?? {})[props.__instanceId]) ?? undefined;
  const singleState = useSelector(state => state['pega-offers']) ?? undefined;
  const state = reduxPerInstance ? perInstanceState : singleState;
  return (
    <PEGA_OFFERS
      {...propsAndApis(props, PEGA_OFFERS_pckg)}
      theme={theme}
      dispatch={dispatch}
      config={config}
      state={state}
      language={language}
      i18n={TranslationService.moduleT('pega-offers')}>
    </PEGA_OFFERS>
  );
};
const BARRING_MANAGEMENT_wrapper = props => {
  const dispatch = useDispatch();
  const language = useSelector(state => state.language && state.language.language);
  const reduxPerInstance = BARRING_MANAGEMENT_pckg['app-framework'] && BARRING_MANAGEMENT_pckg['app-framework'].reduxPerInstance;
  const config = useSelector(state => (state.config.data && state.config.data.widgets["barring-management"] && state.config.data.widgets["barring-management"][props.__instanceId]) ?? undefined);
  const perInstanceState = useSelector(state => (state['barring-management'] ?? {})[props.__instanceId]) ?? undefined;
  const singleState = useSelector(state => state['barring-management']) ?? undefined;
  const state = reduxPerInstance ? perInstanceState : singleState;
  return (
    <BARRING_MANAGEMENT
      {...propsAndApis(props, BARRING_MANAGEMENT_pckg)}
      theme={theme}
      dispatch={dispatch}
      config={config}
      state={state}
      language={language}
      i18n={TranslationService.moduleT('barring-management')}>
    </BARRING_MANAGEMENT>
  );
};
const CUSTOMER_DASHBOARD_ADDONS_wrapper = props => {
  const dispatch = useDispatch();
  const language = useSelector(state => state.language && state.language.language);
  const reduxPerInstance = CUSTOMER_DASHBOARD_ADDONS_pckg['app-framework'] && CUSTOMER_DASHBOARD_ADDONS_pckg['app-framework'].reduxPerInstance;
  const config = useSelector(state => (state.config.data && state.config.data.widgets["customer-dashboard-addons"] && state.config.data.widgets["customer-dashboard-addons"][props.__instanceId]) ?? undefined);
  const perInstanceState = useSelector(state => (state['customer-dashboard-addons'] ?? {})[props.__instanceId]) ?? undefined;
  const singleState = useSelector(state => state['customer-dashboard-addons']) ?? undefined;
  const state = reduxPerInstance ? perInstanceState : singleState;
  return (
    <CUSTOMER_DASHBOARD_ADDONS
      {...propsAndApis(props, CUSTOMER_DASHBOARD_ADDONS_pckg)}
      theme={theme}
      dispatch={dispatch}
      config={config}
      state={state}
      language={language}
      i18n={TranslationService.moduleT('customer-dashboard-addons')}>
    </CUSTOMER_DASHBOARD_ADDONS>
  );
};
const PROCESS_FLOW_STEPPER_wrapper = props => {
  const dispatch = useDispatch();
  const language = useSelector(state => state.language && state.language.language);
  const reduxPerInstance = PROCESS_FLOW_STEPPER_pckg['app-framework'] && PROCESS_FLOW_STEPPER_pckg['app-framework'].reduxPerInstance;
  const config = useSelector(state => (state.config.data && state.config.data.widgets["process-flow-stepper"] && state.config.data.widgets["process-flow-stepper"][props.__instanceId]) ?? undefined);
  const perInstanceState = useSelector(state => (state['process-flow-stepper'] ?? {})[props.__instanceId]) ?? undefined;
  const singleState = useSelector(state => state['process-flow-stepper']) ?? undefined;
  const state = reduxPerInstance ? perInstanceState : singleState;
  return (
    <PROCESS_FLOW_STEPPER
      {...propsAndApis(props, PROCESS_FLOW_STEPPER_pckg)}
      theme={theme}
      dispatch={dispatch}
      config={config}
      state={state}
      language={language}
      i18n={TranslationService.moduleT('process-flow-stepper')}>
    </PROCESS_FLOW_STEPPER>
  );
};
const TOPUP_SELECTOR_wrapper = props => {
  const dispatch = useDispatch();
  const language = useSelector(state => state.language && state.language.language);
  const reduxPerInstance = TOPUP_SELECTOR_pckg['app-framework'] && TOPUP_SELECTOR_pckg['app-framework'].reduxPerInstance;
  const config = useSelector(state => (state.config.data && state.config.data.widgets["topup-selector"] && state.config.data.widgets["topup-selector"][props.__instanceId]) ?? undefined);
  const perInstanceState = useSelector(state => (state['topup-selector'] ?? {})[props.__instanceId]) ?? undefined;
  const singleState = useSelector(state => state['topup-selector']) ?? undefined;
  const state = reduxPerInstance ? perInstanceState : singleState;
  return (
    <TOPUP_SELECTOR
      {...propsAndApis(props, TOPUP_SELECTOR_pckg)}
      theme={theme}
      dispatch={dispatch}
      config={config}
      state={state}
      language={language}
      i18n={TranslationService.moduleT('topup-selector')}>
    </TOPUP_SELECTOR>
  );
};
const SIM_SELECTOR_wrapper = props => {
  const dispatch = useDispatch();
  const language = useSelector(state => state.language && state.language.language);
  const reduxPerInstance = SIM_SELECTOR_pckg['app-framework'] && SIM_SELECTOR_pckg['app-framework'].reduxPerInstance;
  const config = useSelector(state => (state.config.data && state.config.data.widgets["sim-selector"] && state.config.data.widgets["sim-selector"][props.__instanceId]) ?? undefined);
  const perInstanceState = useSelector(state => (state['sim-selector'] ?? {})[props.__instanceId]) ?? undefined;
  const singleState = useSelector(state => state['sim-selector']) ?? undefined;
  const state = reduxPerInstance ? perInstanceState : singleState;
  return (
    <SIM_SELECTOR
      {...propsAndApis(props, SIM_SELECTOR_pckg)}
      theme={theme}
      dispatch={dispatch}
      config={config}
      state={state}
      language={language}
      i18n={TranslationService.moduleT('sim-selector')}>
    </SIM_SELECTOR>
  );
};
const PRODUCT_SELECTOR_wrapper = props => {
  const dispatch = useDispatch();
  const language = useSelector(state => state.language && state.language.language);
  const reduxPerInstance = PRODUCT_SELECTOR_pckg['app-framework'] && PRODUCT_SELECTOR_pckg['app-framework'].reduxPerInstance;
  const config = useSelector(state => (state.config.data && state.config.data.widgets["product-selector"] && state.config.data.widgets["product-selector"][props.__instanceId]) ?? undefined);
  const perInstanceState = useSelector(state => (state['product-selector'] ?? {})[props.__instanceId]) ?? undefined;
  const singleState = useSelector(state => state['product-selector']) ?? undefined;
  const state = reduxPerInstance ? perInstanceState : singleState;
  return (
    <PRODUCT_SELECTOR
      {...propsAndApis(props, PRODUCT_SELECTOR_pckg)}
      theme={theme}
      dispatch={dispatch}
      config={config}
      state={state}
      language={language}
      i18n={TranslationService.moduleT('product-selector')}>
    </PRODUCT_SELECTOR>
  );
};
const PEGA_DETAILS_wrapper = props => {
  const dispatch = useDispatch();
  const language = useSelector(state => state.language && state.language.language);
  const reduxPerInstance = PEGA_DETAILS_pckg['app-framework'] && PEGA_DETAILS_pckg['app-framework'].reduxPerInstance;
  const config = useSelector(state => (state.config.data && state.config.data.widgets["pega-details"] && state.config.data.widgets["pega-details"][props.__instanceId]) ?? undefined);
  const perInstanceState = useSelector(state => (state['pega-details'] ?? {})[props.__instanceId]) ?? undefined;
  const singleState = useSelector(state => state['pega-details']) ?? undefined;
  const state = reduxPerInstance ? perInstanceState : singleState;
  return (
    <PEGA_DETAILS
      {...propsAndApis(props, PEGA_DETAILS_pckg)}
      theme={theme}
      dispatch={dispatch}
      config={config}
      state={state}
      language={language}
      i18n={TranslationService.moduleT('pega-details')}>
    </PEGA_DETAILS>
  );
};
const ADDON_DETAILS_POPUP_wrapper = props => {
  const dispatch = useDispatch();
  const language = useSelector(state => state.language && state.language.language);
  const reduxPerInstance = ADDON_DETAILS_POPUP_pckg['app-framework'] && ADDON_DETAILS_POPUP_pckg['app-framework'].reduxPerInstance;
  const config = useSelector(state => (state.config.data && state.config.data.widgets["addon-details-popup"] && state.config.data.widgets["addon-details-popup"][props.__instanceId]) ?? undefined);
  const perInstanceState = useSelector(state => (state['addon-details-popup'] ?? {})[props.__instanceId]) ?? undefined;
  const singleState = useSelector(state => state['addon-details-popup']) ?? undefined;
  const state = reduxPerInstance ? perInstanceState : singleState;
  return (
    <ADDON_DETAILS_POPUP
      {...propsAndApis(props, ADDON_DETAILS_POPUP_pckg)}
      theme={theme}
      dispatch={dispatch}
      config={config}
      state={state}
      language={language}
      i18n={TranslationService.moduleT('addon-details-popup')}>
    </ADDON_DETAILS_POPUP>
  );
};

const noDcl = props => {
  const {smWidth, mdWidth, ...rest} = props;
  return rest;
};

const propsAndActions = actionPropNames => props =>
    Object.fromEntries(Object.entries(props).map(([propName, val]) => {
      if(actionPropNames.includes(propName)) {
        const newVal = (event) => {
          if(val.function) return selectors[val.function](event);
          const state = store.getState();
          const { type, instanceId, payload, payloadFromState, payloadFromEvent } = val;
          if(typeof (payload || payloadFromState || payloadFromEvent) === "object") {
            const pfs = Object.fromEntries(
                Object.entries(payloadFromState || {}).map(([k, v]) => [k, dot.pick(v, state)]));
            dot.object(pfs);
            const pfe = Object.fromEntries(
                Object.entries(payloadFromEvent || {}).map(([k, v]) => [k, dot.pick(v, event)]));
            dot.object(pfe);
            const newPayload = merge.all([payload, pfs, pfe]);
            store.dispatch({type, instanceId, payload: newPayload});
          } else {
            const newPayload = payload
                || (payloadFromState && dot.pick(payloadFromState, state))
                || (payloadFromEvent && dot.pick(payloadFromEvent, event));
            store.dispatch({type, instanceId, payload: newPayload});
          }
        };
        return [propName, newVal];
      } else {
        return [propName, val];
      }
    }));

for (const atomicsCategory of atomics) {
  let categoryWidgets = atomicsCategory.widgets;
  for (const atomicWidget of categoryWidgets) {
    let actionPropNames = Object.entries(atomicWidget.propsSchema.properties).filter(([_, p]) => p.__role === "action" || p.__role === "component").map(([k,_]) => k);
    const propFunc = propsAndActions(actionPropNames);
    const Component = atomicWidget.component;
    atomicWidget.component = props => (<Component {...propFunc(props)}/>);
  }
}

const atomicsRegistry = Object.fromEntries(
atomics.flatMap(c => c.widgets).map(w => [w.name, w.component])
);

const widgets = [
  {
    _id: `Widget-custom`,
    name: "Custom",
    version: "1.0.0",
    djr: {
      __component: "Fragment",
      __props: {
        children: [
          {
            __component: "Typography",
            __props: {
              variant: "h1",
              text: "Inventory",
            },
          },
          {
            __component: "Typography",
            __props: {
              variant: "h2",
            },
            __render: "props.my.text",
            __mapStateToProps: {
              text: "props.my.text",
            },
          },
          {
            __component: "Loader",
            __render: "props.my.text|not",
            __props: {
            }
          },
        ],
      },
    },
    propsSchema: {
      type: "object",
      properties: {
        text: {
          type: "string",
        },
      },
    },
    author: "vfteam",
    description: "Custom DJR",
    images: [],
    appFramework: {
      "widget-category": "Atomics",
      theme: ["MVA10"],
      author: "vfteam",
      description: "Custom DJR",
    },
  },
];

const specializeForId = (djrOrig, id) => {
  const djr = cloneDeep(djrOrig);
  jp.apply(djr, `$..__mapStateToProps`, msp => {
    Object.entries(msp).forEach(([k, v]) => {
      if (v.startsWith(`props.my.`)) {
        // eslint-disable-next-line no-param-reassign
        msp[k] = v.replaceAll("props.my.", `props.${id}.`);
      }
    });
    return msp;
  });
  jp.apply(djr, `$..__render`, v => v.startsWith(`props.my.`) ? v.replaceAll("props.my.", `props.${id}.`) : v);
  return djr;
};

const DjrComponent = ({ name, id, ...props }) => {
  const componentJson = widgets.find(w => w.name === name).djr;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setProps(id, props));
  }, [props, id, dispatch]);
  const djr = specializeForId(componentJson, id);
  return render(djr);
};

const Iterate = ({ iterateeSelector, propName, elemSelector, children }) => {
  const iteratee = useSelector(state => dot.pick(iterateeSelector, state));
  return (
      <>
        {iteratee.flatMap(elem =>
            children.map(child =>
                React.cloneElement(child, {
                  [propName] : (elemSelector ? dot.pick(elemSelector, elem) : elem),
                  id: uuidv4()
                })))}
      </>
  );
}

const registry = {
  ...atomicsRegistry,
  Iterate,
  CardHeader: () => <div></div>,
  Grid: props => <MaterialGrid container {...noDcl(props)} />,
  GridItem: props => <MaterialGrid item {...noDcl(props)} />,
  Box: props => <Box {...props} i18n={TranslationService.moduleT('R10-gr-sample-product-list')}></Box>,
  Acronym,
  Sidebar,
  Router: props => <ConnectedRouter history={history} {...props}></ConnectedRouter>,
  Switch,
  Redirect,
  PrivateRoute,
  OauthCode,
  NotFoundPage,
  Signin,
  Logout,
  Fragment,
  Route,
  Link,
  'agent-basic-info': AGENT_BASIC_INFO_wrapper,
  'agentlogin': AGENTLOGIN_wrapper,
  'menu-actions': MENU_ACTIONS_wrapper,
  'customer-search-widget': CUSTOMER_SEARCH_WIDGET_wrapper,
  'customer-information-banner': CUSTOMER_INFORMATION_BANNER_wrapper,
  'products-and-services': PRODUCTS_AND_SERVICES_wrapper,
  'navigation-header': NAVIGATION_HEADER_wrapper,
  'error-modal': ERROR_MODAL_wrapper,
  'generic-actions': GENERIC_ACTIONS_wrapper,
  'topup': TOPUP_wrapper,
  'plan-type-modal': PLAN_TYPE_MODAL_wrapper,
  'bundle-details': BUNDLE_DETAILS_wrapper,
  'service-details-header': SERVICE_DETAILS_HEADER_wrapper,
  'service-tags': SERVICE_TAGS_wrapper,
  'plans-card': PLANS_CARD_wrapper,
  'consumption-card': CONSUMPTION_CARD_wrapper,
  'pega-offers': PEGA_OFFERS_wrapper,
  'barring-management': BARRING_MANAGEMENT_wrapper,
  'customer-dashboard-addons': CUSTOMER_DASHBOARD_ADDONS_wrapper,
  'process-flow-stepper': PROCESS_FLOW_STEPPER_wrapper,
  'topup-selector': TOPUP_SELECTOR_wrapper,
  'sim-selector': SIM_SELECTOR_wrapper,
  'product-selector': PRODUCT_SELECTOR_wrapper,
  'pega-details': PEGA_DETAILS_wrapper,
  'addon-details-popup': ADDON_DETAILS_POPUP_wrapper,
}

// eslint-disable-next-line no-plusplus
for (let i = 0; i < widgets.length; i++) {
  const w = widgets[i];
  // eslint-disable-next-line no-param-reassign
  w.component = DjrComponent;
  registry[w.name] = w.component;
}

const render = createDynamicJourneyRenderer({
  plugins: {
    initialSetup: {
      connect,
      selectors,
    },
    preRender: {
      __mapStateToProps: mapStateToPropsPlugin,
      __render: conditionalRenderingPlugin,
    },
    propsPlugin: componentRenderingPlugin,
  },
  registry
});

export { render, registry };
